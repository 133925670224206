import { gql, useMutation } from '@apollo/client';
import { useSwrQuery } from 'components/hooks/swrHooks';
import { Notification } from 'generated/schema-types';
import moment from 'moment';
import { FunctionComponent, useContext, useEffect } from 'react';
import { TimesIcon } from '../../public/icons';
import UserContext from '../context/UserContext';
import LoadingSpinner from './LoadingSpinner';

interface NotificationListProps {
  notificationListDisplayed: boolean;
  setNotificationListDisplayed: (value: boolean) => void;
  setMenuDisplayed: (value: boolean) => void;
}

export interface UnreadNotificationsQueryResult {
  notifications: Notification[];
}
export interface UnreadNotificationsQueryVariables {
  id: string;
}
export const GET_UNREAD_NOTIFICATIONS_QUERY = gql`
  query getUnreadNotifications($id: String!) {
    notifications(
      orderBy: { createdAt: desc }
      where: { OR: [{ recipients: { some: { employee: { id: { equals: $id } }, read: { equals: false } } } }] }
    ) {
      id
      createdAt
      title
      subtitle
      message
      type
      category
      read
      url
    }
  }
`;

interface ReadNotificationsMutationResult {
  updateOneNotification: Notification;
}
interface ReadNotificationsMutationVariables {
  employeeId: string;
  notificationId: string;
}
const READ_NOTIFICATION_MUTATION = gql`
  mutation readNotification($employeeId: String!, $notificationId: String!) {
    updateOneNotification(
      where: { id: $notificationId }
      data: {
        recipients: {
          update: {
            data: { read: true }
            where: { employeeId_notificationId: { employeeId: $employeeId, notificationId: $notificationId } }
          }
        }
      }
    ) {
      id
      createdAt
      title
      subtitle
      message
      type
      category
      read
      url
    }
  }
`;

const NotificationList: FunctionComponent<NotificationListProps> = ({
  notificationListDisplayed,
  setNotificationListDisplayed,
  setMenuDisplayed
}) => {
  const user = useContext(UserContext);

  /**
   * Side effects
   */
  useEffect(() => {
    notificationListDisplayed && setMenuDisplayed(false);
  }, [notificationListDisplayed]);

  /**
   * Apollo hooks
   */
  // const { data, loading, error } = useSwrQuery<UnreadNotificationsQueryResult, UnreadNotificationsQueryVariables>(
  //   GET_UNREAD_NOTIFICATIONS_QUERY,
  //   { variables: { id: user?.id ?? '' } }
  // );
  // const [readNotificationMutation] = useMutation<ReadNotificationsMutationResult, ReadNotificationsMutationVariables>(
  //   READ_NOTIFICATION_MUTATION
  // );

  // const readNotification = (id: string): void => {
  //   readNotificationMutation({
  //     variables: { notificationId: id, employeeId: user?.id ?? '' }
  //   });
  // };

  // let unreadNotifications: Notification[] = [];
  // if (!loading && !error) {
  //   unreadNotifications = data ? data.notifications.filter(n => !n.read) : [];
  // }

  return (
    <>
      {notificationListDisplayed && (
        <span
          id='notificationListBackground'
          className='notification-list-background'
          onClick={(): void => setNotificationListDisplayed(false)}
        ></span>
      )}
      <div className={`notification-list g-container ${notificationListDisplayed ? ' displayed' : ''}`}>
        <p className='header'>Notificaciones</p>
        <div className='default-message p-2 text-gray-600'>Aún no recibes notificaciones</div>
      </div>
      <style jsx>{`
        .notification-list {
          background-color: #ffffff;
          border-bottom-right-radius: 35px;
          border-bottom: 2px solid #e4e4e4;
          border-right: 2px solid #e4e4e4;
          z-index: 15;
          transition: 0.5s;

          justify-content: flex-start;
          flex-direction: column;

          position: fixed;
          height: 100%;
          width: 360px;
          padding-left: 70px;
          left: -650px;
          flex-wrap: nowrap;
        }
        .displayed {
          left: 0;
        }

        .header {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 10px;
          width: calc(100% - 20px);
          text-align: center;
          margin: 20px 10px 0 10px;
          border-bottom: 2px solid #e4e4e4;
        }
        .container {
          overflow-y: auto;
        }

        .default-message,
        .notification-row {
          width: calc(100% - 20px);
          text-align: center;
          padding: 10px 0;
          margin: 0 10px;
        }

        .notification-row {
          position: relative;
          text-align: left;
        }
        .notification-row p {
          text-align: left;
          margin-bottom: 2px;
        }

        .remove-notification {
          position: absolute;
          top: 10px;
          right: 0;
        }
        .title {
          text-transform: uppercase;
          color: var(--gray900);
        }
        .subtitle {
          position: relative;
          color: var(--gray900);
          width: max-content;
        }
        .subtitle.read:after {
          content: '';
          background-color: var(--primary-color);
          display: block;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          position: absolute;
          right: -12px;
          bottom: calc(50% - 3.5px);
        }
        .message {
          font-size: 0.9rem;
          color: var(--gray700);
        }
        .timestamp {
          color: var(--gray600);
        }

        .notification-list-background {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 9;
        }

        @media (max-width: 640px) {
          .notification-list {
            width: unset;
            padding: 0;
            top: 68px;
            bottom: 10px;
            height: calc(100vh - 78px);
            border-radius: 10px;
            border: 2px solid #e4e4e4;
          }
          .displayed {
            left: 10px;
            right: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default NotificationList;
